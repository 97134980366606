import { Autocomplete, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { Car, GraphDisplayType, GraphFormInput, GraphType, Measure, SpecialPermission } from 'shared';
import { displayEnum } from '../../../utils/pipes';
import NERAutocomplete from '../../../components/NERAutocomplete';
import { useEffect, useState } from 'react';
import { graphTypeToAutoCompleteValue, specialPermissionToAutoCompleteValue } from '../../../utils/statistics.utils';

interface GraphFormViewProps {
  control: Control<GraphFormInput, any>;
  errors: FieldErrors<GraphFormInput>;
  cars: Car[];
}

export const GraphFormView: React.FC<GraphFormViewProps> = ({ control, errors, cars }) => {
  const [startTimeDatePickerOpen, setStartTimeDatePickerOpen] = useState(false);
  const [endTimeDatePickerOpen, setEndTimeDatePickerOpen] = useState(false);
  const [carMap, setCarMap] = useState(new Map<string, Car>());

  useEffect(() => {
    const tempSet = new Map<string, Car>();

    cars.forEach((car) => {
      tempSet.set(car.id, car);
    });

    setCarMap(tempSet);
  }, [cars]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel>Start Date</FormLabel>
          <Controller
            control={control}
            name="startTime"
            render={({ field: { value, onChange } }) => {
              return (
                <DatePicker
                  value={value}
                  open={startTimeDatePickerOpen}
                  onClose={() => setStartTimeDatePickerOpen(false)}
                  onOpen={() => setStartTimeDatePickerOpen(true)}
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!errors.startTime,
                      helperText: errors.startTime?.message,
                      onClick: () => setStartTimeDatePickerOpen(true),
                      inputProps: { readOnly: true },
                      fullWidth: true
                    }
                  }}
                />
              );
            }}
          />
        </FormControl>
        <FormHelperText error={!!errors.startTime}>{errors.startTime?.message}</FormHelperText>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel>End Date</FormLabel>
          <Controller
            control={control}
            name="endTime"
            render={({ field: { value, onChange } }) => {
              return (
                <DatePicker
                  value={value}
                  open={endTimeDatePickerOpen}
                  onClose={() => setEndTimeDatePickerOpen(false)}
                  onOpen={() => setEndTimeDatePickerOpen(true)}
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!errors.endTime,
                      helperText: errors.endTime?.message,
                      onClick: () => setEndTimeDatePickerOpen(true),
                      inputProps: { readOnly: true },
                      fullWidth: true
                    }
                  }}
                />
              );
            }}
          />
        </FormControl>
        <FormHelperText error={!!errors.endTime}>{errors.endTime?.message}</FormHelperText>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel sx={{ alignSelf: 'start' }}>Graph Type</FormLabel>
          <Controller
            control={control}
            name={'graphDisplayType'}
            render={({ field }) => (
              <Select
                displayEmpty
                label={'Change Graph Display Type'}
                sx={{ height: 56, width: '100%', textAlign: 'left' }}
                fullWidth
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }
                }}
                {...field}
              >
                {Object.values(GraphDisplayType).map((graphType) => {
                  return (
                    <MenuItem key={graphType} value={graphType}>
                      {displayEnum(graphType)}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </FormControl>
        <FormHelperText error={!!errors.graphType}>{errors.graphType?.message}</FormHelperText>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel sx={{ alignSelf: 'start' }}>Measure</FormLabel>
          <Controller
            control={control}
            name={'measure'}
            render={({ field }) => (
              <Select
                displayEmpty
                fullWidth
                label={'Change Measure'}
                sx={{ height: 56, width: '100%', textAlign: 'left' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }
                }}
                {...field}
              >
                {Object.values(Measure).map((measure: Measure) => {
                  return (
                    <MenuItem key={measure} value={measure}>
                      {displayEnum(measure)}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </FormControl>
        <FormHelperText error={!!errors.measure}>{errors.measure?.message}</FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormLabel>Select Data</FormLabel>
          <Controller
            name="graphType"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <NERAutocomplete
                  sx={{ width: '100%' }}
                  id="graphTypeSelector"
                  onChange={(_, tableValue) => onChange(tableValue?.id)}
                  size="medium"
                  value={value ? graphTypeToAutoCompleteValue(value) : null}
                  placeholder="Select a graph type"
                  options={Object.values(GraphType).map(graphTypeToAutoCompleteValue)}
                  errorMessage={errors.graphType}
                />
              );
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel>Select Cars To Segment Data By</FormLabel>
          <Controller
            name="carIds"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  filterSelectedOptions
                  multiple
                  id="carSelector"
                  options={cars}
                  value={value.map((carId) => carMap.get(carId))}
                  onChange={(_event, newValue) => onChange(newValue.map((car) => car?.id))}
                  getOptionLabel={(option) => option?.name ?? ''}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" placeholder="Select Cars (Leave Blank For All Cars)" />
                  )}
                />
              );
            }}
          />
          <FormHelperText error={!!errors.carIds}>{errors.carIds?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel>Additional Permissions to Apply to the Graph</FormLabel>
          <Controller
            name="specialPermissions"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  multiple
                  id="permissionsSelector"
                  options={Object.values(SpecialPermission).map(specialPermissionToAutoCompleteValue)}
                  value={value.map(specialPermissionToAutoCompleteValue)}
                  onChange={(_event, newValue) => onChange(newValue.map((autoCompleteValue) => autoCompleteValue.id))}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" placeholder="Select Any Additional Permissions" />
                  )}
                />
              );
            }}
          />
          <FormHelperText error={!!errors.specialPermissions}>{errors.specialPermissions?.message}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
