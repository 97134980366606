/*
 * This file is part of NER's FinishLine and licensed under GNU AGPLv3.
 * See the LICENSE file in the repository root folder for details.
 */

import axios from '../utils/axios';
import {
  ProjectPreview,
  SetUserScheduleSettingsPayload,
  Task,
  User,
  UserScheduleSettings,
  UserSecureSettings,
  UserWithScheduleSettings
} from 'shared';
import { apiUrls } from '../utils/urls';
import {
  authUserTransformer,
  userScheduleSettingsTransformer,
  userTransformer,
  userWithScheduleSettingsTransformer
} from './transformers/users.transformers';
import { AuthenticatedUser, UserSettings } from 'shared';
import { projectPreviewTransformer } from './transformers/projects.transformers';
import { taskTransformer } from './transformers/tasks.transformers';

/**
 * Fetches all users.
 */
export const getAllUsers = () => {
  return axios.get<UserWithScheduleSettings[]>(apiUrls.users(), {
    transformResponse: (data) => JSON.parse(data).map(userWithScheduleSettingsTransformer)
  });
};

/**
 * Fetch a single user.
 *
 * @param id User ID of the requested user.
 */
export const getSingleUser = (id: string) => {
  return axios.get<User>(apiUrls.usersById(id), {
    transformResponse: (data) => userTransformer(JSON.parse(data))
  });
};

/**
 * Log in a user.
 *
 * @param id_token The login token for the user.
 */
export const logUserIn = (id_token: string) => {
  return axios.post<AuthenticatedUser>(
    apiUrls.usersLogin(),
    { id_token },
    { transformResponse: (data) => authUserTransformer(JSON.parse(data)) }
  );
};

/**
 * Attempts to get the current logged in user
 *
 * @returns The authenticated user
 */
export const getCurrentUser = () => {
  return axios.get<AuthenticatedUser>(apiUrls.currentUser(), {
    transformResponse: (data) => authUserTransformer(JSON.parse(data)),
    timeout: 5000
  });
};

/**
 * Log in a dev user.
 *
 * @param userId The userId to log in.
 */
export const logUserInDev = (userId: string) => {
  return axios.post<AuthenticatedUser>(
    apiUrls.usersLoginDev(),
    { userId },
    { transformResponse: (data) => authUserTransformer(JSON.parse(data)) }
  );
};

/**
 * Fetch the user settings for a single user.
 *
 * @param id User ID of the requested user's settings.
 */
export const getSingleUserSettings = (id: string) => {
  return axios.get<UserSettings>(apiUrls.userSettingsByUserId(id));
};

/**
 * Fetch the secure settings for the current user
 */
export const getCurrentUserSecureSettings = () => {
  return axios.get<UserSecureSettings>(apiUrls.currentUserSecureSettings());
};

/**
 * Fetch a user's favorite projects
 *
 * @param id User ID of the requested user's favorite projects.
 */
export const getUsersFavoriteProjects = (id: string) => {
  return axios.get<ProjectPreview[]>(apiUrls.userFavoriteProjects(id), {
    transformResponse: (data) => JSON.parse(data).map(projectPreviewTransformer)
  });
};

/**
 * Fetch a user's secure settings
 *
 * @param id User ID of the requested user's secure settings
 * @returns the secure settings
 */
export const getUserSecureSettings = (id: string) => {
  return axios.get<UserSecureSettings>(apiUrls.userSecureSettings(id));
};

/**
 * Fetch a user's schedule settings
 *
 * @param userId User ID of the requested user's schedule settings
 * @returns the schedule settings
 */
export const getUserScheduleSettings = (userId: string) => {
  return axios.get<UserScheduleSettings>(apiUrls.userScheduleSettings(userId), {
    transformResponse: (data) => userScheduleSettingsTransformer(JSON.parse(data))
  });
};

/**
 * Update the given user's settings by UserId
 */
export const updateUserSettings = (id: string, settings: UserSettings) => {
  return axios.post<{ message: string }>(apiUrls.userSettingsByUserId(id), settings);
};

/**
 * Update the given user's secure settings by UserId
 */
export const updateUserSecureSettings = (settings: UserSecureSettings) => {
  return axios.post<{ message: string }>(apiUrls.userSecureSettingsSet(), settings);
};

/**
 * Update the given user's schedule settings by UserId
 */
export const updateUserScheduleSettings = (settings: SetUserScheduleSettingsPayload) => {
  return axios.post<UserScheduleSettings>(apiUrls.userScheduleSettingsSet(), settings);
};

export const updateUserRole = (id: string, role: string) => {
  return axios.post<{ message: string }>(apiUrls.userRoleByUserId(id), { role });
};

export const getUserTasks = (id: string) => {
  return axios.get<Task[]>(apiUrls.userTasks(id), {
    transformResponse: (data) => JSON.parse(data).map(taskTransformer)
  });
};

export const getManyUserTasks = (userIds: string[]) => {
  return axios.post<Task[]>(
    apiUrls.manyUserTasks(),
    { userIds },
    {
      transformResponse: (data) => JSON.parse(data).map(taskTransformer)
    }
  );
};

export const logUserOut = () => {
  return axios.post<{ message: string }>(apiUrls.logUserOut());
};
